<template>
  <div>
    <v-card class="mx-auto mt-10" max-width="800" min-height="550">
      <v-system-bar color="indigo darken-2" dark>
        <v-spacer></v-spacer>
        Chat
      </v-system-bar>

      <v-container fluid>
        <v-row dense>
          <v-col cols="4">
            <ListaConversas />
          </v-col>
          <v-col cols="8">
            <Conversa :chat="chat" :id="chat.id" v-if="chat" v-show="show" :key="chat.id" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import Conversa from "@/components/Chat/Conversa.vue";
import ListaConversas from "@/components/Chat/ListaConversas.vue";
export default {
  components: { ListaConversas, Conversa },
  data() {
    return {
      show: false,
      chat: null,
    };
  },
  mounted() {
    this.$root.$on("selectChat", (item) => {
      
      if (item.id) {
        console.log('entrou aqui')
        this.show = true;
        this.chat = item;
      } else this.show = false;
    });
  },
};
</script>

<style>
#tipofile {
  display: none;
}
audio.non-downloadable::-webkit-media-controls-enclosure {
  overflow: hidden;
}
audio.non-downloadable::-webkit-media-controls-panel {
  width: calc(100% + 32px);
}

video::-webkit-media-controls-mute-button {
  overflow: hidden;
  display: none;
}
.audio {
  background-color: transparent !important;
}

.input-mensagem {
  height: 200px;
  background-color: #ffff !important;
}

.content {
  padding: 8px;
  background-color: #90ee90;
  border-radius: 10px;
  display: inline-block;
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  max-width: 50%;
  word-wrap: break-word;
}

.content-user {
  padding: 8px;
  background-color: #87cefa;
  border-radius: 10px;
  display: inline-block;
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  max-width: 50%;
  word-wrap: break-word;
  float: right;
}

.autor {
  color: rgb(134, 133, 133);
  font-size: 14pt;
}

.horario {
  float: right;
  color: rgb(134, 133, 133);
  font-size: 10pt;
}
</style>
