<template>
  <v-footer style="background-color: #ededed">
    <v-row class="text-center">
      <v-spacer />
      <v-col md="11" sm="11">
        <span>
          {{ nameOfChat(chat) }}
        </span>
      </v-col>
      <v-spacer />
      <list-files :chat="chat" v-show="chat.type != 'etiqueta'" />
    </v-row>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
import ListFiles from "../Dialog/ListFiles.vue";
export default {
  components: { ListFiles },
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      me: "getMe",
    }),
  },

  data() {
    return {
      selectedItem: null,
      mensagem: null,
      chats: [],
      listFiles: false,
    };
  },

  methods: {
    nameOfChat(item) {
      if (item.type == "privado") {
        return item.users.find((el) => el.id != this.me.id).name;
      }

      return item.name;
    },
  },
};
</script>

<style></style>
