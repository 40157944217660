<template>
  <v-container class="px-0" style="background-color: #f2f2f2">
    <v-row class="text-center d-flex align-end">
      <v-col md="1" sm="1" small>
        <v-file-input class="mt-0 pt-0" hide-input v-model="arquivo">
        </v-file-input>
      </v-col>
      <v-col md="10" sm="10" v-if="arquivo != null">
        <v-chip> {{ arquivo.name }} </v-chip>
      </v-col>
      <v-col id="chat-no-message" md="10" sm="10" v-else>
        <v-textarea
          id="chat-message-typer-textarea"
          auto-grow
          rows="1"
          v-model="message"
          label="Digite uma mensagem..."
          required
          outlined
          background-color="white"
        ></v-textarea>
      </v-col>
      <v-col md="1" sm="1" small v-if="arquivo != null">
        <v-icon @click="uploadFile"> fas fa-cloud-upload-alt </v-icon>
      </v-col>
      <v-col md="1" sm="1" small v-else>
        <v-icon @click="send" v-show="message != ''">
          fas fa-paper-plane
        </v-icon>
        <v-icon
          @click="getAudio"
          v-show="message == '' && mediaRecorder == null"
        >
          fas fa-microphone
        </v-icon>
        <span v-show="audioDetected"> {{ i }} </span>
        <v-icon
          @click="closeAudio"
          color="red"
          v-show="message == '' && mediaRecorder != null"
        >
          fas fa-microphone
        </v-icon>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      me: "getMe",
    }),
  },

  data() {
    return {
      arquivo: null,
      message: null,
      audioDetected: false,
      mediaRecorder: null,
      i: 0,
      midia: null,
      loadingFile: {
        value: 0,
        indeterminate: true,
        active: false,
        query: false,
      },
    };
  },

  methods: {
    closeAudio() {
      clearInterval(this.audioRegister);
      this.audioDetected = false;
      this.i = 0;
      this.mediaRecorder.stop();
      this.midia = null;
      this.mediaRecorder = null;
    },
    send() {
      let message = {
        user_id: this.me.id,
        views: [this.me.id],
        corpo: this.message,
        tipo: this.message != null ? "texto" : "arquivo",
        chat_id: this.id,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        user: { id: this.me.id, name: this.me.name, role_id: this.me.role_id },
        midia: this.midia ? this.midia.midia : "",
        tipo_midia: this.midia ? this.midia.tipo_midia : "",
        nameMidia:
          this.midia && this.midia.nameMidia != null
            ? this.midia.nameMidia
            : "",
      };

      try {
        window.db
          .collection("chat")
          .add(message)
          .then(() => {
            this.$root.$emit("loadingFile", {
              active: false,
              indeterminate: true,
              value: 0,
              arquivo: 0,
            });
            this.loadingFile.active = false;
            this.loadingFile.indeterminate = true;
            this.loadingFile.value = 0;
            this.arquivo = null;
          });

        window.db
          .collection("chats")
          .doc(this.id)
          .update({
            updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          });
      } catch (error) {
        console.log(error);
      } finally {
        window.scrollTo(0, document.querySelector(".mensagens").scrollBottom);
      }

      console.log(message);
      this.message = "";
    },

    async getAudio() {
      this.i = 0;
      this.audioDetected = true;
      var self = this;
      this.audioRegister = setInterval(function() {
        self.i++;
        console.log(self.i);
      }, 1000);
      this.midia = null;
      await navigator.mediaDevices.getUserMedia({ audio: true }).then(
        (stream) => {
          this.mediaRecorder = new MediaRecorder(stream);
          let chunks = [];
          this.mediaRecorder.ondataavailable = (data) => {
            chunks.push(data.data);
          };
          this.mediaRecorder.onstop = () => {
            const blob = new Blob(chunks, { type: "audio/ogg; code=opus" });

            this.audio = blob;

            console.log(blob);

            const reader = new FileReader();
            reader.readAsDataURL(blob);

            var name = Math.random() * 99 * Math.random() + ".ogg";
            var storageRef = window.storage.ref(
              "chats/" + this.id + "/" + name
            );

            var metadata = {
              contentType: "audio/ogg",
            };

            this.midia = {
              midia: name,
              nameMidia: name,
              tipo_midia: "audio/ogg",
            };

            console.log(this.midia);
            let self = this;
            storageRef.put(this.audio, metadata).then(function(snapshot) {
              console.log(snapshot);
              var storage = window.storage;
              var storageRef = storage.ref();

              storageRef
                .child("chats/" + self.id + "/" + self.midia.midia)
                .getDownloadURL()
                .then(function(uri) {
                  console.log("uri" + uri);
                  self.midia.midia = uri;
                  console.log("name" + self.midia.midia);
                  self.message = null;
                  self.send();
                });
            });
            console.log("stop");
          };
          this.mediaRecorder.start();
        },
        (err) => {
          console.log(err);
        }
      );
    },

    uploadFile() {
      this.loadingFile.active = true;

      this.$root.$emit("loadingFile", this.loadingFile);
      var name = Math.random() * 99 * Math.random();

      var ext = this.arquivo.name.split(".");
      let nameOfFile = ext[0];
      ext = ext[ext.length - 1];
      var nameComplete = name + "%%%" + nameOfFile + "." + ext;
      console.log(nameComplete);
      console.log(ext);
      var storageRef = window.storage.ref(
        "chats/" + this.id + "/" + nameComplete
      );

      var metadata = {
        contentType: this.arquivo.type,
      };

      this.midia = {
        midia: nameComplete,
        tipo_midia: this.arquivo.type,
        nameMidia: this.arquivo.name,
      };

      let self = this;
      storageRef.put(this.arquivo, metadata).then(function(snapshot) {
        self.loadingFile.indeterminate = false;
        self.loadingFile.value =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        self.$root.$emit("loadingFile", self.loadingFile);

        var storage = window.storage;
        var storageRef = storage.ref();

        storageRef
          .child("chats/" + self.id + "/" + self.midia.midia)
          .getDownloadURL()
          .then(function(uri) {
            console.log("uri" + uri);
            self.midia.midia = uri;
            console.log("name" + self.midia.midia);
            self.message = null;
            self.send();
          });
      });
    },
  },
};
</script>

<style>
#chat-message-typer-textarea {
  max-height: 115px;
  overflow-y: auto;
}

#chat-no-message .v-text-field__details {
  display: none;
}

#chat-no-message .v-input__slot {
  margin-bottom: 0px !important;
}
</style>
