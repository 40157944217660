<template>
  <div class="text-center">
    <v-dialog max-width="600px" v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          color="success"
          v-bind="attrs"
          v-on="on"
          @click="dialog = true"
          small
        >
          Etiqueta
        </v-chip>
      </template>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row class="d-flex align-center">
              <v-col cols="12">
                <v-text-field v-model="name" required label="Titulo" />
              </v-col>
              <v-col cols="12">
                <v-btn color="primary" @click="storeChat()"> Criar </v-btn>
              </v-col>
            </v-row>
            <br />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ToastService from "../../services/ToastService";
import firebase from "firebase/app";
export default {
  data() {
    return {
      dialog: false,
      name: "",
    };
  },

  computed: {
    ...mapGetters({
      me: "getMe",
    }),
  },

  methods: {
    storeChat() {
      try {
        if (this.name == "") {
          ToastService("Defina um titulo", "error");
          return;
        }

        this.store();
      } catch (error) {
        console.log(error);
      }
    },

    store() {
      let users = [];

      users.push({ id: this.me.id, name: this.me.name });

      window.db
        .collection("chats")
        .add({
          name: this.name,
          type: "etiqueta",
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          users: users,
        })
        .then(() => {
          ToastService("Nova etiqueta criado com sucesso", "success");

          this.dialog = false;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style></style>
