<template>
  <div>
    <v-list>
      <v-subheader class="text-center">
        <NovoChat />
        <NovoGrupo />
        <NovaEtiqueta />
      </v-subheader>

      <v-list-item-group v-model="chatIndex" color="primary">
        <template v-for="(item, i) in chats">
          <v-list-item :key="i" @click="openMessage(item)">
            <v-list-item-avatar>
              <v-avatar color="primary" v-show="item.type == 'privado'">
                <v-icon color="white">
                  fas fa-user
                </v-icon>
              </v-avatar>
              <v-avatar color="green" v-show="item.type == 'grupo'">
                <v-icon color="white">
                  fas fa-users
                </v-icon>
              </v-avatar>

              <v-avatar color="yellow" v-show="item.type == 'etiqueta'">
                <v-icon color="white">
                  fas fa-tags
                </v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ nameOfChat(item) }}
                <v-badge
                  v-show="item.type != 'etiqueta' && item.notifications > 0"
                  color="red"
                  :content="item.notifications"
                ></v-badge>
              </v-list-item-title>
              <v-list-item-subtitle v-if="item.message">
                {{
                  item.message.user_id === me.id
                    ? "Você"
                    : item.message.user.name
                }}:
                {{ item.message.corpo }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="'divider' + i"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ToastService from "../../services/ToastService";
import NovaEtiqueta from "./NovaEtiqueta.vue";
import NovoChat from "./NovoChat.vue";
import NovoGrupo from "./NovoGrupo.vue";
export default {
  components: { NovoChat, NovoGrupo, NovaEtiqueta },
  data() {
    return {
      chats: [],
      notifys: [],
      chatIndex: null,
    };
  },

  computed: {
    ...mapGetters({
      me: "getMe",
    }),
  },

  filters: {},

  mounted() {
    this.getChats();
  },

  methods: {
    async getMessages(id) {
      try {
        window.db
          .collection("chat")
          .where("chat_id", "==", id)
          .orderBy("created_at")
          .onSnapshot((query) => {
            let allMessages = [];
            let count = 0;

            let me = this.me;

            query.forEach((obj) => {
              allMessages.push({ id: obj.id, ...obj.data() });

              let data = obj.data();

              const newMessage = data.views.find((userId) => userId == me.id);

              if (!newMessage) {
                count = count + 1;
              }
            });

            let index = this.chats.findIndex((el) => el.id === id);

            this.chats[index].message = allMessages[allMessages.length - 1];
            this.chats[index].notifications = count;
          });
      } catch (exception) {
        ToastService(exception, "error");
      }
    },

    async getChats() {
      window.db
        .collection("chats")
        .orderBy("updated_at", "desc")
        .onSnapshot((query) => {
          let allChats = [];
          query.forEach((obj) => {
            let exist = obj.data().users.find((el) => el.id == this.me.id);

            if (exist) {
              allChats.push({
                id: obj.id,
                ...obj.data(),
                message: { user: {} },
                notifications: 0,
              });

              this.getMessages(obj.id);
            }
          });

          this.chats = allChats;
        });
    },

    openMessage(item) {
      this.$root.$emit("selectChat", item);
    },

    nameOfChat(item) {
      if (item.type == "privado") {
        return item.users.find((el) => el.id != this.me.id).name;
      }

      return item.name;
    },
  },
};
</script>

<style></style>
