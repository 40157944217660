<template>
  <div class="text-center">
    <v-dialog max-width="600px" v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          color="success"
          v-bind="attrs"
          v-on="on"
          @click="dialog = true"
          small
        >
          chat
        </v-chip>
      </template>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row class="d-flex align-center">
              <v-col cols="10">
                <v-autocomplete
                  v-model="user_id"
                  :items="users"
                  item-text="name"
                  item-value="id"
                  :menu-props="{ maxHeight: '400' }"
                  label="Selecione"
                  hint="Escolha o usuario"
                  persistent-hint
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-btn color="primary" @click="storeChat()"> Criar </v-btn>
              </v-col>
            </v-row>
            <br />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import instanceAxios from "../../plugins/axios";
import ToastService from "../../services/ToastService";
import firebase from "firebase/app";
export default {
  data() {
    return {
      dialog: false,
      typeChat: null,
      user_id: null,
      users: [],
    };
  },

  computed: {
    ...mapGetters({
      me: "getMe",
    }),
  },

  mounted() {
    this.getUsers();
  },

  methods: {
    async getUsers() {
      const { data } = await instanceAxios("users/chats");

      this.users = data;
    },

    storeChat() {
      try {
        if (this.user_id == null) {
          ToastService("Escolha um usuario", "error");
        }

        let exists = false;

        window.db
          .collection("chats")
          .where("type", "==", "privado")
          .get()
          .then((query) => {
            console.log(query);
            query.forEach((obj) => {
              const chat = obj.data();

              if (
                chat.users.find((el) => el.id == this.me.id) &&
                chat.users.find((el) => el.id == this.user_id)
              ) {
                ToastService(
                  "Você já possui um chat com esse usuario",
                  "error"
                );
                exists = true;
              }
            });

            if (!exists) {
              this.store();
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    store() {
      let users = [];

      users.push({ id: this.me.id, name: this.me.name });

      let newUser = this.users.find((el) => el.id == this.user_id);

      users.push({ id: newUser.id, name: newUser.name });

      window.db
        .collection("chats")
        .add({
          name: "privado",
          type: "privado",
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
          users: users,
        })
        .then(() => {
          ToastService("Novo chat criado com sucesso", "success");
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style></style>
