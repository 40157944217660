<template>
  <v-col @click.right.prevent="openMenu">
    <!-- Quando é texto -->
    <div
      v-if="message.tipo == 'texto'"
      :class="message.user_id == me.id ? 'content-user' : 'content'"
      :id="'mensagem-' + message.created_at"
    >
      <v-menu offset-y top>
        <template v-slot:activator="{ on, attrs }">
          <div v-show="message.tipo == 'texto'" v-bind="attrs" v-on="on">
            <v-icon style="position: relative; float: right" x-small>
              fas fa-ellipsis-v
            </v-icon>
          </div>
        </template>
        <v-list v-show="chat.type != 'etiqueta'">
          <v-list-item
            v-for="chat in chats"
            :key="'etiqueta ' + chat.id"
            v-show="chat.type == 'etiqueta'"
            @click="salvarMensagemEtiqueta(message, chat)"
          >
            <v-list-item-title>
              {{ chat.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list v-show="me.id == message.user_id">
          <v-list-item @click="excluirMensagem(message)">
            Apagar
          </v-list-item>
        </v-list>
      </v-menu>
      <span class="autor">
        {{ message.user.name }}
      </span>
      <br />
      <span style="white-space: pre-wrap;">{{ message.corpo }}</span>

      <div v-show="message.tipo != 'texto'"></div>
      <br />
      <span class="horario">
        {{ message.created_at | formatDate }}
      </span>
    </div>

    <!-- Quando é audio -->
    <div
      v-if="message.tipo != 'texto' && message.tipo_midia == 'audio/ogg'"
      :class="message.user_id == me.id ? 'content-user' : 'content'"
      :id="'mensagem-' + message.created_at"
    >
      <v-row>
        <v-col md="12">
          <v-menu offset-y top>
            <template v-slot:activator="{ on, attrs }">
              <div v-show="message.tipo == 'arquivo'" v-bind="attrs" v-on="on">
                <v-icon style="position: relative; float: right" x-small>
                  fas fa-ellipsis-v
                </v-icon>
              </div>
            </template>
            <v-list v-show="chat.type != 'etiqueta'">
              <v-list-item
                v-for="chat in chats"
                :key="'etiqueta ' + chat.id"
                v-show="chat.type == 'etiquetas'"
                @click="salvarMensagemEtiqueta(mensagem, chat)"
              >
                <v-list-item-title>
                  {{ chat }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list v-show="me.id == message.user_id">
              <v-list-item @click="excluirMensagem(mensagem)">
                Apagar
              </v-list-item>
            </v-list>
          </v-menu>
          <span class="autor">
            {{ message.user.name }}
          </span>
          <br />
          <Player :file="message.midia"></Player>
          <br />
          <span class="horario">
            {{ message.created_at | formatDate }}
          </span>
        </v-col>
      </v-row>
    </div>

    <!-- Quando é arquivo -->

    <div
      v-if="message.tipo != 'texto' && message.tipo_midia != 'audio/ogg'"
      :class="message.user_id == me.id ? 'content-user' : 'content'"
      :id="'mensagem-' + message.created_at"
    >
      <v-menu offset-y top>
        <template v-slot:activator="{ on, attrs }">
          <div v-show="message.tipo == 'arquivo'" v-bind="attrs" v-on="on">
            <v-icon style="position: relative; float: right" x-small>
              fas fa-ellipsis-v
            </v-icon>
          </div>
        </template>
        <v-list v-show="chat.type != 'etiqueta'">
          <v-list-item
            v-for="chat in chats"
            :key="'etiqueta ' + chat.id"
            v-show="chat.type == 'etiqueta'"
            @click="salvarMensagemEtiqueta(mensagem, chat)"
          >
            <v-list-item-title>
              {{ chat.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list v-show="me.id == message.user_id">
          <v-list-item @click="excluirMensagem(mensagem)">
            Apagar
          </v-list-item>
        </v-list>
      </v-menu>
      <span class="autor"> {{ message.user.name }} </span>
      <br />
      <a :href="message.midia" style="text-decoration: none" target="_blank">
        <v-chip>
          <span v-if="message.nameMidia">
            {{ message.nameMidia }}
          </span>
          <v-icon class="pl-4">
            fas fa-cloud-download-alt
          </v-icon>
        </v-chip>
        <br />
        <span class="horario">
          {{ message.created_at | formatDate }}
        </span>
      </a>
    </div>
  </v-col>
</template>

<script>
import Player from "@/components/Player/Index";
import { mapGetters } from "vuex";
import moment from "moment";
import ToastService from "../../services/ToastService";
import firebase from "firebase/app";

export default {
  components: {
    Player,
  },

  computed: {
    ...mapGetters({
      me: "getMe",
    }),
  },

  filters: {
    formatDate: function(el) {
      if (!el.seconds == null) return;

      return moment.unix(el.seconds).format("HH:mm DD/MM/YYYY");
    },
  },

  props: {
    message: {
      type: Object,
      required: true,
    },

    chat: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      chats: [],
    };
  },

  mounted() {
    this.getChats();
    this.checkMessage();
  },

  methods: {
    checkMessage() {
      let newView = this.message.views;
      if(newView.find(el => el == this.me.id)) return; 

      newView.push(this.me.id);
      window.db
        .collection("chat")
        .doc(this.message.id)
        .update({ views: newView });
    },
    async getChats() {
      window.db
        .collection("chats")
        .orderBy("updated_at", "desc")
        .onSnapshot((query) => {
          let allChats = [];

          query.forEach((obj) => {
            allChats.push({ id: obj.id, ...obj.data() });
          });

          this.chats = allChats;
        });
    },

    excluirMensagem(mensagem) {
      try {
        console.log(mensagem);
        window.db
          .collection("chat")
          .doc(mensagem.id)
          .delete();

        ToastService("Mensagem excluida para todos", "success");
      } catch (error) {
        console.log(error);
        ToastService("Erro ao excluir mensagem", "error");
      }
    },

    salvarMensagemEtiqueta(mensagem, etiqueta) {
      var msg = mensagem;
      msg.chat_id = etiqueta.id;

      window.db
        .collection("chat")
        .add(msg)
        .then((res) => {
          console.log(res);
        });

      window.db
        .collection("chats")
        .doc(etiqueta.id)
        .update({
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        });
    },
  },
};
</script>

<style></style>
