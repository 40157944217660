<template>
  <v-col class="chat">
    <TopBarChat :chat="chat" />
    <v-container class="mensagens pb-0" ref="mens" v-if="id">
      <InputConversa :id="id" v-if="chat.type != 'etiqueta'" />
      <div class="text-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
          v-show="loadingMessages"
        ></v-progress-circular>
      </div>
      <div key="chat" v-if="id">
        <v-row v-for="message in messages" :key="`message${message.id}`">
          <Mensagem :chat="chat" :message="message" v-if="message.created_at" />
        </v-row>
        <v-row>
          <v-progress-linear
            :value="loadingFile.value"
            :active="loadingFile.active"
            :indeterminate="loadingFile.indeterminate"
            :query="loadingFile.query"
          ></v-progress-linear>
        </v-row>
      </div>
    </v-container>
  </v-col>
</template>

<script>
import ToastService from "../../services/ToastService";
import InputConversa from "./InputConversa.vue";
import Mensagem from "./Mensagem.vue";
import TopBarChat from "./TopBarChat.vue";
export default {
  props: {
    chat: {
      type: Object,
    },
    id: {
      type: String,
    },
  },
  components: { InputConversa, TopBarChat, Mensagem },
  data() {
    return {
      loadingMessages: false,
      messages: [],
      loadingFile: {
        value: null,
        active: false,
      },
      unsubChat: null,
      unsubMessage: null,
    };
  },

  watch: {
    id(newValue) {
      this.getMessages(newValue);
    },
  },

  mounted() {
    console.log("mounted");
    this.getMessages(this.id);

    this.$root.$on("loadingFile", (load) => (this.loadingFile = load));
  },

  async beforeDestroy() {
    console.log("beforeDestroy");
  },

  methods: {
    selectChat(item) {
      this.unsubChat = window.db
        .collection("chats")
        .doc(item.id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.id = doc.id;
            this.chat = { id: doc.id, ...doc.data() };

            this.getMessages();
          } else {
            ToastService("Chat não encontrado", "error");
          }
        })
        .catch(() => {
          ToastService("Erro ao buscar o chat", "error");
        });
    },

    async getMessages(id) {
      try {
        console.log(this.id);
        this.loadingMessages = true;
        this.unsubMessage = window.db
          .collection("chat")
          .where("chat_id", "==", id)
          .orderBy("created_at")
          .onSnapshot((query) => {
            let allMessages = [];
            query.forEach((obj) => {
              allMessages.push({ id: obj.id, ...obj.data() });
            });

            this.messages = allMessages;
            this.loadingMessages = false;
          });
      } catch (exception) {
        ToastService(exception, "error");
      }
    },
  },
};
</script>

<style>
.mensagens {
  background-color: #f2f2f2;
  display: flex;
  overflow: auto;
  flex-direction: column-reverse;
  position: relative;
  height: 100%;
}

.chat {
  display: block;
  height: calc(100vh - 12.5rem);
}
</style>
